import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Elmitra`}</p>
    <ul>
      <li parentName="ul">{`Friend`}</li>
      <li parentName="ul">{`Child`}</li>
      <li parentName="ul">{`Parent`}</li>
      <li parentName="ul">{`Lover`}</li>
      <li parentName="ul">{`Teacher`}</li>
      <li parentName="ul">{`Doctor`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      